import Swal from "sweetalert2";

window.Swal = Swal;

const defaultTimer = 5000;
const messageErrorDefault = "Não conseguimos atender o pedido, tente novamente mais tarde!";
const messageWarningDefault = "Ufaaa! Não apagamos nada.";

window.toastMessage = (message, type = "info", position = "top-end", timer = defaultTimer) => {
	const toastMessageF = Swal.mixin({
	    toast: true,
	    position: position,
	    showConfirmButton: true,
	    timer: timer,
	});

	toastMessageF.fire({
        icon: type,
	    title: message
	});
};

window.toastSuccess = (message) => {
	const success = Swal.mixin({
	    toast: true,
	    position: 'top-end',
	    showConfirmButton: true,
	    timer: defaultTimer,
	});

	success.fire({
	    icon: 'success',
	    title: message
	});
};

/**
 *
 * @param message {string}
 * @param type {string}
 * @param position {string}
 * @param timer {number}
 */
window.swallCustom = (message, type, position, timer = defaultTimer) => {
    Swal.fire({
        position: position,
        icon: type,
        title: message,
        showConfirmButton: true,
        timer: timer
    })
};

window.swallSuccess = (message, type = 'success', position = 'center', timer = 10000) => {
	Swal.fire({
        position: position,
        icon: type,
        title: message,
        showConfirmButton: true,
        timer: timer
    })
};

window.swallInfo = (message, type = 'info', position = 'center', timer = defaultTimer) => {
    Swal.fire({
        position: position,
        icon: type,
        title: message,
        showConfirmButton: true,
        timer: timer
    })
};

window.toastInfo = (message) => {
	const filterWithoutValue = Swal.mixin({
	    toast: true,
	    position: 'top-end',
	    showConfirmButton: true,
	    timer: defaultTimer,
	});

	filterWithoutValue.fire({
        icon: "info",
	    title: message
	});
};

window.toastWarning = (message = messageWarningDefault) => {
	const warning = Swal.mixin({
	    toast: true,
	    position: 'top-end',
	    showConfirmButton: true,
	    timer: defaultTimer,
	});

	warning.fire({
        icon: 'warning',
	    title: message
	});
};

window.toastError = (message = messageErrorDefault) => {
	const success = Swal.mixin({
	    toast: true,
	    position: 'top-end',
	    showConfirmButton: true,
	    timer: defaultTimer,
	});

	success.fire({
        icon: 'error',
	    title: message
	});
};

window.alertConfirm = ({url, message = "Tem certeza que deseja apagar este item?", nameUser = null, messageError = false}) => {

    const swallWithBootstrapButtons = Swal.mixin({
        buttonsStyling: true
    });

    if(nameUser === null) {
        nameUser = userAuth.name;
    }

    return new Promise((resolve, reject) => {
        swallWithBootstrapButtons.fire({
            html: `<b>${nameUser}!</b><br/> ${message}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Apagar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                axios.delete(url).then(response => {
                    toastSuccess('Apagado com sucesso!');
                    resolve('confirm', response)
                }).catch(error => {
                    if (messageError) {
                        toastError(error.message || error.data?.message || messageErrorDefault);
                    }
                    console.error('alert confirm:', error)
                    reject(error)
                });

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                toastInfo();
                resolve('cancel', result)
            }
        }).catch(error => {
            if (messageError) {
                toastError(error.message || error.data?.message || messageErrorDefault);
            }
            console.error('alert confirm: ', error)
            reject('alert confirm: ', error)
        });
    })
}
